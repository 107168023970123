<template>
  <ul class="custom-list">
    <li v-for="(option, index) in options" :key="index" class="list-item">
      <button
        v-if="option.children && option.children.length > 0"
        @click="toggleExpansion(index)"
        class="expansion-button"
      >
        <i
          :class="[
            'mdi',
            isExpanded(index) ? 'mdi-chevron-up' : 'mdi-chevron-down'
          ]"
        ></i>
      </button>
      <span
        :class="{
          'list-item-title': option.children && option.children.length > 0,
          'black-dot': !option.children || option.children.length === 0
        }"
        >{{ option.title }}</span
      >
      <custom-list
        v-if="
          option.children && option.children.length > 0 && isExpanded(index)
        "
        :options="option.children"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "CustomList",
  props: ["options"],
  data() {
    return {
      expandedItems: []
    };
  },
  methods: {
    toggleExpansion(index) {
      if (this.isExpanded(index)) {
        this.expandedItems.splice(this.expandedItems.indexOf(index), 1);
      } else {
        this.expandedItems.push(index);
      }
    },
    isExpanded(index) {
      return this.expandedItems.includes(index);
    }
  }
};
</script>

<style scoped>
.custom-list {
  list-style-type: none;
  margin-left: 15px;
}

.list-item {
  margin-bottom: 8px;
  position: relative;
}

.expansion-button {
  margin-right: 5px;
  padding: 3px;
  font-size: 12px;
}

.list-item-title {
  font-size: 14px;
}

.black-dot::before {
  content: "\2022";
  color: black;
  margin-right: 5px;
  padding: 7px;
}
</style>
