<template>
  <v-card>
    <v-card-title class="headline primary--text"
      ><span> {{ title }} </span></v-card-title
    >
    <v-card-text class="text-left" v-if="!passwordRestored">
      {{ text }}
    </v-card-text>

    <!-- Nueva contraseña -->
    <v-card-text
      class="headline primary--text text-center text-h6"
      v-if="passwordRestored"
    >
      <v-subheader v-if="passwordRestored" class="justify-center"
        >Copiar contraseña y mandarla al usuario correspondiente.</v-subheader
      >

      <span>{{ newPassword }}</span>
      <v-subheader v-if="passwordRestored" class="justify-center"
        >Recomiende cambiar la contraseña al iniciar sesión.</v-subheader
      >
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close" :disabled="isLoading">{{
        passwordRestored ? "Cerrar" : "Cancelar"
      }}</v-btn>
      <v-btn
        text
        color="primary"
        dark
        v-if="!passwordRestored"
        @click="confirmRestorePassword()"
        :loading="isLoading"
        >Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";

export default {
  name: "RestaurarContraseña",
  props: {
    usuario: { type: Object, required: true }
  },
  data() {
    return {
      title: enums.titles.RESTAURAR_CONTRASEÑA,
      text: "Se generará una contraseña aleatoria.",
      passwordRestored: false,
      newPassword: null,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      restorePassword: "AdministracionSistema/restorePassword",
      setAlert: "user/setAlert"
    }),
    async confirmRestorePassword() {
      this.isLoading = true;
      try {
        const response = await this.restorePassword(this.usuario.usuId);
        if (response.status === 200) {
          this.title = "Contraseña restaurada";
          this.passwordRestored = true;
          this.setAlert({
            type: "success",
            message: "Contraseña restaurada con éxito."
          });
          this.newPassword = response.data.data;
        }
      } catch (error) {}
      this.isLoading = false;
    },
    close() {
      this.$emit("closeAndReload");
    },
    confirm() {
      this.$emit("restorePassword");
    }
  }
};
</script>

<style></style>
