<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="usuariosItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="usuId"
          show-expand
          :expanded.sync="expanded"
        >
          <template v-slot:[`item.deshabilitado`]="{ item }">
            <v-icon v-if="item.deshabilitado" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <!-- Expansion de usuario -->
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios de carga y modificación</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end" v-if="canCreate">
                  <v-btn
                    color="primary"
                    @click="openModalEdit()"
                    class="to-right"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalMenuOptions(item)"
                >
                  {{ menuIcon }}
                </v-icon>
              </template>
              <span>Opciones de menú</span>
            </v-tooltip>
            <v-tooltip left v-if="canRestorePassword">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalRestorePassword(item)"
                >
                  {{ restoreIcon }}
                </v-icon>
              </template>
              <span>Restaurar contraseña</span>
            </v-tooltip>
            <v-tooltip left v-if="canChangeState">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openChangeStateModal(item)"
                >
                  {{
                    item.deshabilitado
                      ? "mdi-checkbox-marked-circle-outline"
                      : "mdi-checkbox-marked-circle-minus-outline"
                  }}
                </v-icon>
              </template>
              <span v-if="item.deshabilitado">Habilitar usuario</span>
              <span v-else>Deshabilitar usuario</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEdit(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar usuario</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalDeleteUsuario(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar usuario</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="isOpenModalEdit"
      v-model="isOpenModalEdit"
      :max-width="editUsuarioItem ? '70%' : '60%'"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditUsuario
        :usuario="editUsuarioItem"
        @closeAndReload="closeAndReload"
      ></EditUsuario>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    <v-dialog
      v-model="changeStateModal"
      max-width="30%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary--text"
          ><span>{{
            editUsuarioItem != null && editUsuarioItem.deshabilitado
              ? "Habilitar usuario"
              : "Deshabilitar usuario"
          }}</span></v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAndReload" :disabled="isChangeStateLoading"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="primary"
            dark
            @click="confirmChangeStateUser()"
            :loading="isChangeStateLoading"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="requestTokenModal"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary--text"
          ><span>¿Volver a pedir token de autenticación? </span></v-card-title
        >
        <v-card-text class="text-left"
          >Se le pedirá el token de autenticación a todos los usuarios, que
          tengan el doble factor de autenticación habilitado, en el próximo
          inicio de sesión sesión.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAndReload" :disabled="isRequestTokenLoading"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="primary"
            dark
            @click="confirmRequestToken()"
            :loading="isRequestTokenLoading"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="modalRestorePassword"
      v-model="modalRestorePassword"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <RestaurarContrasena
        :usuario="editUsuarioItem"
        @closeAndReload="closeAndReload"
      ></RestaurarContrasena>
    </v-dialog>
    <v-dialog
      v-if="modalMenuOptions"
      v-model="modalMenuOptions"
      max-width="50%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <!-- Le declaro la card y sus propiedades acá ya que sino el bucle deja feo el componente -->
      <v-card :loading="loadingMenuOptions">
        <v-card-title>
          <span class="primary--text">Opciones de menú disponibles</span>
        </v-card-title>
        <v-card-text class="py-0">
          <div class="text-center mt-4" v-if="loadingMenuOptions">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div class="text-center mt-4">
            <span v-if="!loadingMenuOptions && menuOptions.length === 0"
              >El usuario seleccionado no tiene acceso a ninguna opción de menú.
              <br />
              Para darle acceso a las opciones de menú, debe asignarlo a un
              grupo.</span
            >
          </div>
          <OpcionesMenuUsuario
            :options="menuOptions"
            @closeAndReload="closeAndReload"
          ></OpcionesMenuUsuario>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeAndReload"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditUsuario from "@/components/modules/administracion-sistema/EditUsuario.vue";
import RestaurarContrasena from "@/components/modules/administracion-sistema/RestaurarContraseña.vue";
import OpcionesMenuUsuario from "@/components/modules/administracion-sistema/OpcionesMenuUsuario.vue";

export default {
  name: "GestionUsuarios",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
    EditUsuario,
    OpcionesMenuUsuario,
    RestaurarContrasena
  },
  data: () => ({
    routeToGo: "ConfiguracionAdmSistema",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    title: enums.titles.GESTION_USUARIOS,
    titleDelete: "¿Desea eliminar el usuario?",
    search: "",
    optionCode: enums.webSiteOptions.GESTION_USUARIOS,
    editUsuarioItem: null,
    expanded: [],
    usuariosItems: [],
    headers: [
      {
        text: "Usuario",
        value: "usuNom",
        sortable: false
      },
      {
        text: "Grupo/s",
        value: "usuGrupos",
        sortable: false
      },
      {
        text: "Deshabilitado",
        value: "deshabilitado",
        sortable: false,
        align: "center"
      },
      {
        text: "Acciones",
        value: "actions",
        width: "10%",
        sortable: false,
        align: "end"
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null,
    isLoading: false,
    showDeleteModal: false,
    loadingBtnDelete: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    canCreate: false,
    canEdit: false,
    canChangeState: false,
    canDelete: false,
    canRestorePassword: false,
    isOpenModalEdit: false,
    idToDelete: null,
    checkIcon: enums.icons.CHECK_OUTLINE,
    restoreIcon: enums.icons.RESTORE_ICON,
    menuIcon: enums.icons.MENU_ICON,
    changeStateModal: false,
    isChangeStateLoading: false,
    requestTokenModal: false,
    isRequestTokenLoading: false,
    modalRestorePassword: false,
    modalMenuOptions: false,
    menuOptions: [],
    loadingMenuOptions: false
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPermisos();
    this.loadUsuarios();
  },
  methods: {
    ...mapActions({
      getGestionUsuarios: "AdministracionSistema/getGestionUsuarios",
      deleteUsuario: "AdministracionSistema/deleteUsuario",
      changeStateUser: "AdministracionSistema/changeStateUser",
      requestTwoFactorAuthenticationUsersAgain:
        "AdministracionSistema/requestTwoFactorAuthenticationUsersAgain",
      getUserMenuOptions: "AdministracionSistema/getUserMenuOptions",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_USUARIO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_USUARIO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.CAMBIAR_ESTADO:
            this.canChangeState = true;
            break;
          case enums.modules.adminSistema.permissions.RESTAURAR_CONTRASEÑA:
            this.canRestorePassword = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_USUARIO:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadUsuarios() {
      try {
        this.isLoading = true;
        const response = await this.getGestionUsuarios();
        this.usuariosItems = response;
      } catch (error) {}
      this.isLoading = false;
    },
    openModalDeleteUsuario(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.usuId;
    },
    async confirmDelete() {
      try {
        this.loadingBtnDelete = true;
        const response = await this.deleteUsuario(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Usuario eliminado con éxito."
          });
          this.loadingBtnDelete = false;
          if (response.data.data.paramGralTFA) this.requestTokenModal = true;
          else this.loadUsuarios();
        }
      } catch (error) {
        this.showDeleteModal = false;
      }
      this.loadingBtnDelete = false;
    },
    openModalEdit(item) {
      this.isOpenModalEdit = true;
      this.editUsuarioItem = item;
    },
    closeAndReload() {
      this.isOpenModalEdit = this.changeStateModal = this.requestTokenModal = this.modalRestorePassword = this.modalMenuOptions = false;
      this.menuOptions = [];
      this.loadUsuarios();
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    openChangeStateModal(item) {
      this.changeStateModal = true;
      this.editUsuarioItem = item;
    },
    async confirmChangeStateUser() {
      try {
        this.isChangeStateLoading = true;
        const response = await this.changeStateUser({
          id: this.editUsuarioItem.usuId,
          estado: !this.editUsuarioItem.deshabilitado
        });
        if (response.status === 200) {
          this.changeStateModal = false;
          this.setAlert({
            type: "success",
            message: `Usuario ${
              this.editUsuarioItem.deshabilitado
                ? "habilitado"
                : "deshabilitado"
            } con éxito.`
          });
          this.isChangeStateLoading = false;
          if (
            response.data.data.paramGralTFA &&
            !this.editUsuarioItem.deshabilitado
          )
            this.requestTokenModal = true;
          else this.loadUsuarios();
        }
      } catch (error) {
        this.changeStateModal = false;
      }
      this.isChangeStateLoading = false;
    },
    async confirmRequestToken() {
      this.isRequestTokenLoading = true;
      try {
        const response = await this.requestTwoFactorAuthenticationUsersAgain();
        if (response.status === 200) {
          this.requestTokenModal = false;
          this.setAlert({
            type: "success",
            message: "Guardado con éxito."
          });
        }
        this.isRequestTokenLoading = false;
        this.loadUsuarios();
      } catch (error) {
        this.requestTokenModal = false;
      }
      this.isRequestTokenLoading = false;
    },
    openModalRestorePassword(item) {
      this.modalRestorePassword = true;
      this.editUsuarioItem = item;
    },
    async openModalMenuOptions(item) {
      this.modalMenuOptions = true;
      try {
        this.loadingMenuOptions = true;
        this.menuOptions = await this.getUserMenuOptions(item.usuId);
      } catch (error) {}
      this.loadingMenuOptions = false;
    }
  }
};
</script>

<style scoped></style>
