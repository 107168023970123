<template
  ><v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Nombre de usuario -->
            <v-col cols="12" :md="!this.usuario ? '4' : '4'" class="py-0">
              <v-text-field
                tabindex="1"
                v-model.trim="userName"
                type="text"
                label="Nombre de usuario (*)"
                dense
                outlined
                autocomplete="new-username"
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(userName),
                    rules.minLength(userName, 1),
                    rules.maxLength(userName, 20)
                  ])
                "
              ></v-text-field>
            </v-col>
            <!-- Contraseña -->
            <v-col cols="12" md="4" class="py-0" v-if="!this.usuario">
              <v-text-field
                tabindex="2"
                outlined
                dense
                label="Contraseña (*)"
                :append-icon="
                  !userPassword
                    ? ''
                    : passwordTextType
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append="() => (passwordTextType = !passwordTextType)"
                :type="passwordTextType ? 'password' : 'text'"
                :rules="
                  rules.required.concat([
                    rules.maxLength(userPassword, 25),
                    !/\s/.test(userPassword) ||
                      'La contraseña no puede contener espacios.'
                  ])
                "
                autocomplete="new-password"
                v-model="userPassword"
              ></v-text-field>
            </v-col>
            <!-- Repetir contraseña -->
            <v-col cols="12" md="4" class="py-0" v-if="!this.usuario">
              <v-text-field
                tabindex="3"
                outlined
                dense
                label="Repetir contraseña (*)"
                :append-icon="
                  !repeatUserPassword
                    ? ''
                    : repeatPasswordTextType
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append="
                  () => (repeatPasswordTextType = !repeatPasswordTextType)
                "
                :type="repeatPasswordTextType ? 'password' : 'text'"
                autocomplete="repeat-password"
                :rules="
                  repeatUserPassword !== null
                    ? [
                        rules.samePassword(userPassword, repeatUserPassword),
                        rules.maxLength(repeatUserPassword, 25)
                      ]
                    : rules.required
                "
                v-model="repeatUserPassword"
              ></v-text-field>
            </v-col>
            <!-- Nombre -->
            <v-col cols="12" :md="!this.usuario ? '6' : '4'" class="py-0">
              <v-text-field
                tabindex="4"
                v-model.trim="nombre"
                label="Nombre"
                dense
                outlined
                :rules="[
                  rules.minLength(nombre, 1),
                  rules.maxLength(nombre, 50)
                ]"
              ></v-text-field>
            </v-col>
            <!-- Apellido -->
            <v-col cols="12" :md="!this.usuario ? '6' : '4'" class="py-0">
              <v-text-field
                tabindex="5"
                v-model.trim="apellido"
                label="Apellido"
                dense
                outlined
                :rules="[
                  rules.minLength(apellido, 1),
                  rules.maxLength(apellido, 50)
                ]"
              ></v-text-field>
            </v-col>
            <!-- Grupos de usuario -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                outlined
                tabindex="6"
                clearable
                dense
                multiple
                :items="gruposUsuario"
                v-model="gruposUsuarioSelected"
                label="Grupos de usuario"
                item-text="value"
                item-value="id"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ gruposUsuarioSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- Origen -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="origenSelected"
                :items="origenesItems"
                tabindex="7"
                label="Origen por defecto para emisión de bonos web"
                item-value="id"
                item-text="value"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Entidad facturante -->
            <v-col cols="7" md="7" class="py-0">
              <v-autocomplete
                v-model="entFacSelected"
                :items="entsFacs"
                tabindex="8"
                label="Entidad facturante"
                return-object
                item-value="id"
                item-text="value"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Sucursal -->
            <v-col cols="3" md="3" class="py-0">
              <v-text-field
                v-model.trim="sucursal"
                label="Sucursal"
                dense
                tabindex="9"
                :rules="[rules.minMaxNumber(sucursal, 1, 9999)]"
                v-mask="'####'"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2" class="pt-0">
              <v-btn
                tabindex="10"
                color="primary"
                :disabled="entFacSelected === null || sucursal === null"
                @click="addEntFacSuc()"
              >
                Agregar
              </v-btn>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-data-table
                :headers="headersUsuEntFacSuc"
                :items="usuEntFacSucItems"
                class="elevation-1"
                tabindex="11"
                :loading="isUsuEntFacSucLoading"
                item-key="entFacSucUsuId"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        tabindex="12"
                        size="20"
                        v-on="on"
                        @click="deleteEntFacSuc(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar relación</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn tabindex="13" outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
          tabindex="14"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "EditUsuario",
  props: {
    usuario: {
      type: Object,
      required: false
    }
  },
  directives: { mask },
  data: () => ({
    rules: rules,
    deleteIcon: enums.icons.DELETE,
    formTitle: "",
    isFormValid: false,
    userName: null,
    nombre: null,
    apellido: null,
    userPassword: null,
    repeatUserPassword: null,
    passwordTextType: String,
    repeatPasswordTextType: String,
    soloTipoCompCaja: false,
    origenesItems: [],
    origenSelected: null,
    gruposUsuario: [],
    gruposUsuarioSelected: [],
    entsFacs: [],
    entFacSelected: null,
    sucursal: null,
    isUsuEntFacSucLoading: false,
    headersUsuEntFacSuc: [
      {
        text: "Entidad facturante",
        value: "entFacNom",
        sortable: false
      },
      {
        text: "Sucursal",
        value: "sucursal",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    usuEntFacSucItems: []
  }),
  created() {
    this.setSelects();
  },
  mounted() {
    if (this.usuario) this.setUsuario();
    else this.formTitle = "Nuevo usuario";
  },
  methods: {
    ...mapActions({
      saveUsuario: "AdministracionSistema/saveUsuario",
      getOrigenes: "configAppBenef/getOrigenes",
      getGruposDeUsuarios: "configAfiliaciones/getGruposDeUsuarios",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      setAlert: "user/setAlert"
    }),
    setUsuario() {
      this.formTitle = "Editar usuario";
      this.userName = this.usuario.usuNom;
      this.nombre = this.usuario.nombre;
      this.apellido = this.usuario.apellido;
      this.origenSelected = this.usuario.origenId;
      this.gruposUsuarioSelected = this.usuario.usuGruposIds;
      this.usuario.usuEntFacsSuc.forEach(x => {
        this.usuEntFacSucItems.push({
          entFacId: x.id,
          entFacNom: x.value2,
          sucursal: x.value1
        });
      });
    },
    async setSelects() {
      const origenes = await this.getOrigenes();
      this.origenesItems = origenes;
      const grupos = await this.getGruposDeUsuarios();
      this.gruposUsuario = grupos;
      const entFacs = await this.getEntidadesFacturantes();
      this.entsFacs = entFacs;
    },
    async saveEdit() {
      this.isFormValid = false;
      const data = {
        usuId: this.usuario?.usuId,
        nombre: this.nombre,
        apellido: this.apellido,
        usuNom: this.userName,
        usuPassword: this.userPassword,
        origenId: this.origenSelected,
        usuGruposIds: this.gruposUsuarioSelected,
        usuEntFacsSuc: this.usuEntFacSucItems.map(item => {
          return {
            id: item.entFacId,
            value1: item.sucursal
          };
        })
      };
      try {
        const response = await this.saveUsuario(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    addEntFacSuc() {
      this.isUsuEntFacSucLoading = true;
      // Valido que no exista la misma entidad facturante con la misma sucursal
      const validateItem = this.usuEntFacSucItems.some(
        x =>
          x.entFacId === this.entFacSelected.id &&
          x.sucursal === parseInt(this.sucursal, 10)
      );
      if (validateItem) {
        this.setAlert({
          type: "warning",
          message: "Existe un registro similar con los datos ingresados."
        });
        this.isUsuEntFacSucLoading = false;
        return;
      } else {
        this.usuEntFacSucItems.push({
          entFacId: this.entFacSelected.id,
          entFacNom: this.entFacSelected.value,
          sucursal: parseInt(this.sucursal, 10)
        });
        // Ordeno la lista por nombre de entidad facturante y después por número de sucursal
        this.usuEntFacSucItems.sort((a, b) => {
          if (a.entFacNom < b.entFacNom) return -1;
          if (a.entFacNom > b.entFacNom) return 1;

          if (a.sucursal < b.sucursal) return -1;
          if (a.sucursal > b.sucursal) return 1;

          return 0;
        });
        this.setAlert({ type: "success", message: "Agregado con éxito." });
        this.entFacSelected = this.sucursal = null;
        this.isUsuEntFacSucLoading = false;
      }
    },
    deleteEntFacSuc(item) {
      this.isUsuEntFacSucLoading = true;
      this.usuEntFacSucItems = this.usuEntFacSucItems.filter(x => x !== item);
      this.setAlert({ type: "success", message: "Eliminado con éxito." });
      this.isUsuEntFacSucLoading = false;
    }
  }
};
</script>

<style scoped></style>
