var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"custom-list"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,staticClass:"list-item"},[(option.children && option.children.length > 0)?_c('button',{staticClass:"expansion-button",on:{"click":function($event){return _vm.toggleExpansion(index)}}},[_c('i',{class:[
          'mdi',
          _vm.isExpanded(index) ? 'mdi-chevron-up' : 'mdi-chevron-down'
        ]})]):_vm._e(),_c('span',{class:{
        'list-item-title': option.children && option.children.length > 0,
        'black-dot': !option.children || option.children.length === 0
      }},[_vm._v(_vm._s(option.title))]),(
        option.children && option.children.length > 0 && _vm.isExpanded(index)
      )?_c('custom-list',{attrs:{"options":option.children}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }